/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
	
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
            // All pages
            'common': {
                init: function () {
                    var $win = $(window);
                    var $doc = $(document);
                    var winO = $win.scrollTop();
                    var winH = $win.height();
                    var winW = $win.width();
                    var animatedInClassName = 'animate-in';
                    var $animated;

                    $doc.ready(function () {
						$('.fancybox').fancybox();
						
                        webshims.polyfill();

                        var $exercise = $('.exercise');
                        var $introBg = $('.intro-bg');
                        var $sliderIntroActions = $('.slider-intro .slider-actions');
                        var $sliderIntroTitle = $sliderIntroActions.find('span');
                        var introBgClass = $introBg.attr('class');
                        var sliderIntroTitle = $('.slider-intro');
                        var $timeSlider = $('.time-slider');
                        var isSortBodyVisible = false;
                        var isNavActive;
                        $animated = $('.animate');

                        //Remove from cart
                        $('.table-cart').on('click', '.link-remove', function (event) {
                            $(this).closest('tr').remove();

                            event.preventDefault();
                        });

                        // Credit Card Number Format
                        $('.field-cc').each(function () {
                            $(this).on('input', function () {
                                var creditCardNumber = this.value;

                                creditCardNumber = creditCardNumber.replace(/[^\d]+/g, '');
                                creditCardNumber = creditCardNumber.slice(0, 16);
                                creditCardNumber = creditCardNumber.match(/.{1,4}/g);
                                creditCardNumber = creditCardNumber != null ? creditCardNumber.join(' - ') : '';

                                this.value = creditCardNumber;
                            });
                        });

                        // Credit Card Month Format
                        $('.field-month').each(function () {
                            $(this).on('input', function () {
                                var month = this.value;

                                month = month.replace(/[^\d]+/g, '');
                                month = month.slice(0, 2);

                                this.value = month;
                            });
                        });

                        // Credit Card Year Format
                        $('.field-year').each(function () {
                            $(this).on('input', function () {
                                var year = this.value;

                                year = year.replace(/[^\d]+/g, '');
                                year = year.slice(0, 4);

                                this.value = year;
                            });
                        });


                        //Time slider
                        if ($timeSlider.length) {
                            sliderInit($timeSlider);
                        }

                        //Mobile sorting
                        if (winW <= 767){
                            $('.sort-head').on('click', 'h6', function (event) {

                            
                                var $this = $(this)
                                var $sortBody = $this.parent().next();
                                var $sortNav = $this.next();

                                $this.toggleClass('active')
                                    .next().stop().slideToggle();

                                if ($sortNav.find('.current').length) {
                                    $sortBody.slideToggle();
                                }
                            });
                        } 

                        //Close sort options

                        $('.sort-actions').on('click', '.link-close', function (event) {
                            $(this).closest('.sort-body').slideUp();
                            $('.nav-sort li').removeClass();

                            event.preventDefault();
                        });

                        //Nav sort
                        $('.nav-sort').on('click', 'li.sortby a:not(.order)', function (event) {
                            var $this = $(this);
                            var $parent = $this.parent();
                            var sortTarget = $this.attr('href');
                            var $sortBody = $('.sort-body');


                            if ($this.hasClass('link-expand')) {
                                $(sortTarget).addClass('visible')
                                    .siblings().removeClass('visible');

                                $sortBody.stop(true, true).slideDown();

                                isSortBodyVisible = true;

                                if ($parent.hasClass('current') && isSortBodyVisible) {
                                    $sortBody.stop(true, true).slideUp();

                                    isSortBodyVisible = false;
                                }

                            } else {
                                $sortBody.stop(true, true).slideUp();

                                isSortBodyVisible = false;
                            }

                            $parent.toggleClass('current').removeClass('inactive');

                            isNavActive = $this.closest('ul').find('.current').length < 1;

                            $parent.siblings().toggleClass('inactive', !isNavActive).removeClass('current');

                            event.preventDefault();
                        });

                        //Eq materials
                        equalHeight('.equalize', 767);
                        equalHeight('.slider-featured .slide', 1023);

                        // Homepage Slider
                        if ($('.slider').length) {
                            $('.slider .slides').addClass('owl-carousel');

                            $('.slider-intro .slides').owlCarousel({
                                items: 1,
                                mouseDrag: false,
                                smartSpeed: 600,
                                loop: true,
                                autoplay: true,
                                responsive: {
                                    0: {
                                        stagePadding: 30,
                                        margin: 13
                                    },
                                    768: {
                                        stagePadding: 70,
                                        margin: 0
                                    },
                                    900: {
                                        stagePadding: 100
                                    },
                                    1150: {
                                        stagePadding: 150
                                    },
                                    1439: {
                                        stagePadding: 295
                                    }
                                },
                                onInitialized: function (event) {
                                    var $activeSlide = $(event.currentTarget).find('.active .slide');
                                    var currentBg = $activeSlide.data('bg');
                                    var currentTitle = $activeSlide.data('title');

                                    $sliderIntroTitle.text(currentTitle);


                                    $sliderIntroActions.removeClass().addClass('slider-actions ' + currentBg);
                                    $introBg.removeClass().addClass(introBgClass + ' ' + currentBg);
                                },
                                onChanged: function (event) {
                                    setTimeout(function () {
                                        var $activeSlide = $(event.currentTarget).find('.active .slide');
                                        var currentBg = $activeSlide.data('bg');
                                        var currentTitle = $activeSlide.data('title');

                                        $sliderIntroTitle.text(currentTitle);

                                        $sliderIntroActions.removeClass().addClass('slider-actions ' + currentBg);
                                        $introBg.removeClass().addClass(introBgClass + ' ' + currentBg);
                                    }, 10);
                                }
                            });

                            $('.slider-actions').on('click', 'a', function (event) {
                                var $this = $(this);
                                var $owl = $this.closest('.slider').find('.slides');

                                if ($this.hasClass('slider-prev')) {
                                    $owl.trigger('prev.owl.carousel');
                                } else if ($this.hasClass('slider-next')) {
                                    $owl.trigger('next.owl.carousel');
                                }

                                event.preventDefault();
                            });

                            $('.slider-featured .slides').owlCarousel({
                                items: 1,
                                mouseDrag: false,
                                smartSpeed: 500,
                                loop: true,
                                animateIn: 'fadeInLeft',
                                animateOut: 'fadeOutRight',
                                nav: true,
                                navText: [
                                    '<i class="ico-slider-prev-sm"></i>',
                                    '<i class="ico-slider-next-sm"></i>'
                                ]
                            })
                        }

                        
                        var $sliderSteps = $('.slider-steps .slides');

                        function addDots() {
                            var slides = $sliderSteps.find('.owl-item:not(.cloned)').length;
                            var dots = [];
                            var i = 0;

                            for ( ; i < slides; i++ ) {
                                dots.push('<div>'+ i + '</div>');
                            };

                            $sliderSteps.find('.slide-content').each(function() {
                                $(this).prepend('<div class="slider-paging">'+ dots.join('') +'</div>' );
                            });

                            $sliderSteps.on('click', '.slider-paging div', function() {
                                var idx = parseInt($(this).text(), 10);

                                $(this).addClass('current').siblings().removeClass('current');
                                $('.slider-steps .owl-dot').eq(idx).trigger('click');
                            });
                        };

                        function currentDot() {
                            var current = $sliderSteps.find('.owl-dot.active').index();

                            $('.slider-paging').each(function() {
                                $(this).find('div').eq(current).addClass('current').siblings().removeClass('current');
                            });
                        };

                        $sliderSteps.owlCarousel({
                            items: 1,
                            mouseDrag: false,
                            smartSpeed: 600,
                            loop: true,
                            onInitialized: function() {
                                addDots();
                                currentDot();
                            },
                            onTranslated: function() {
                                currentDot();
                            }
                        });

                        //Show add student
                        $('.add-student').on('click', function (event) {
                            $(this).toggleClass('active')
                                .parent().next().slideToggle();

                            event.preventDefault();
                        });

                        //Table students
                        $('.table-students .student-actions').on('click', '.link-edit', function (event) {
                            $(this).closest('tr').toggleClass('editing');

                            event.preventDefault();
                        });

                        //Nav utilities
                        $('.nav-utilities').on('click', '> span', function (event) {
                            $(this).next().stop().slideToggle()
                                .parent().toggleClass('nav-utilities-expanded');
                        });

                        //Mobile popup
                        $('.popup-trigger').on('click', function (event) {

                            if ($win.width() < 1024) {
                                $(this).parent().toggleClass('popup-visible');

                                event.preventDefault();
                            }
                        });

                        $doc.on('click touchstart', function (event) {
                            if (!$(event.target).closest('.popup-wrapper').length) {
                                $('.popup-wrapper').removeClass('popup-visible');
                            }
                        });

                        //Nav mobile
                        $('.nav-trigger').on('click', function (event) {
                            $(this).toggleClass('active');

                            $('.header-inner').toggleClass('nav-visible');

                            $('.wrapper').toggleClass('wrapper-overflow');

                            event.preventDefault();
                        });

                        //Table students
                        $('.table-students-secondary').on('click', '.link-edit', function (event) {
                            $(this).closest('tr').toggleClass('editing')
                                .find('.field').prop('disabled', function (i, v) {
                                return !v;
                            });

                            setWidth($('.table-students-secondary .field'));

                            event.preventDefault();
                        });


                        $exercise.on('mouseenter', function (event) {
                            var $this = $(this);

                            if ($win.width() > 1023) {
                                $this.find('.exercise-actions').stop().slideDown('fast');
                            }


                        }).on('mouseleave', function (event) {
                            var $this = $(this);

                            if ($win.width() > 1023) {
                                if (!$this.hasClass('exercise-expanded')) {
                                    $this.find('.exercise-actions').stop().slideUp('fast');
                                }
                            }
                        });

                        $('body:not(.build-course-detail) .exercise-actions .link-edit').click(function (event) {

                            var exercise = $(this).closest('.exercise');

                            window.location.hash = exercise.attr('id');

                            exercise.toggleClass('exercise-expanded')
                                .find('.exercise-foot').stop().slideToggle();

                            event.preventDefault();


                        });

                    });

                    $win.on('load', function () {
                        //Students
                        setWidth($('.table-students-secondary .field'));

                    }).on('load scroll', function () {
                        winO = $win.scrollTop();

                        if ($animated.length) {
                            $animated.each(function () {
                                var $section = $(this);

                                $section.toggleClass(animatedInClassName, winO + winH * 0.8 > $section.offset().top);
                            });
                        }

                    }).on('resize', function () {
                        winH = $win.height();
                        winW = $win.width();
                    });

                    function sliderInit(container) {
                        container.each(function () {
                            var $this = $(this);
                            var $labels = $this.find('.time-slider-labels span');
                            var $labelMin = $($labels[0]);
                            var $labelAvrg = $($labels[1])
                            var $labelMax = $($labels[2]);
                            var $rangeSlider = $this.find('.range-slider');
                            var min = $rangeSlider.data('min');
                            var max = $rangeSlider.data('max');
                            var step = $rangeSlider.data('step');
                            var values = $rangeSlider.data('values').split(',');

                            $labelMin.text(min);
                            $labelAvrg.text(max / 2);
                            $labelMax.text(max);

                            $rangeSlider.slider({
                                range: true,
                                min: min,
                                max: max,
                                step: step,
                                values: values,
                                create: function (event, ui) {
                                    var $ui = $(this);
                                    var $leftHandle = $($ui.find('.ui-slider-handle')[0]);
                                    var $rightHandle = $($ui.find('.ui-slider-handle')[1]);

                                    $leftHandle.attr('data-min', values[0]);
                                    $rightHandle.attr('data-max', values[1]);
                                },
                                slide: function (event, ui) {
                                    var $ui = $(this);
                                    var $leftHandle = $($ui.find('.ui-slider-handle')[0]);
                                    var $rightHandle = $($ui.find('.ui-slider-handle')[1]);
                                    var $minInput = $ui.closest('.time-slider').find('.min-input');
                                    var $maxInput = $ui.closest('.time-slider').find('.max-input');

                                    $leftHandle.attr('data-min', ui.values[0]);
                                    $minInput.val(ui.values[0]);
                                    $rightHandle.attr('data-max', ui.values[1]);
                                    $maxInput.val(ui.values[1]);
                                }
                            });

                            $this.find('.time-slider-actions').on('click', 'a', function (event) {
                                var $this = $(this);
                                var $handles = $this.closest('.time-slider').find('.ui-slider-handle');
                                var $leftHandle = $($handles[0]);
                                var $rightHandle = $($handles[1]);
                                var $minInput = $this.closest('.time-slider').find('.min-input');
                                var $maxInput = $this.closest('.time-slider').find('.max-input');

                                if ($this.hasClass('time-less')) {
                                    var sliderVal = $rangeSlider.slider('values', 0);

                                    $rangeSlider.slider('values', 0, sliderVal - step);

                                    sliderVal = $rangeSlider.slider('values', 0);

                                    $leftHandle.attr('data-min', sliderVal);

                                    $minInput.val(sliderVal);

                                } else if ($this.hasClass('time-more')) {
                                    var sliderVal = $rangeSlider.slider('values', 1);

                                    $rangeSlider.slider('values', 1, sliderVal + step);

                                    sliderVal = $rangeSlider.slider('values', 1);

                                    $rightHandle.attr('data-max', sliderVal);

                                    $maxInput.val(sliderVal);
                                }

                                event.preventDefault();

                                //$('.sort-body form').submit();
                            });

                        });
                    }

                    function equalHeight(selector, breakpoint) {
                        var $selector = $(selector);

                        if ($selector.length) {
                            $win.on('load resize', function () {
                                if ($win.width() > breakpoint) {
                                    var maxHeight = 0;

                                    $selector
                                        .height('auto')
                                        .each(function () {
                                            var $column = $(this);

                                            if ($column.height() > maxHeight) {
                                                maxHeight = $column.height();
                                            }
                                        })
                                        .height(maxHeight);
                                } else {
                                    $selector.height('auto');
                                }
                                ;
                            });
                        }
                        ;
                    };

                    function setWidth($element) {
                        $element.each(function () {
                            var $this = $(this);
                            var width = $this.textWidth();

                            $this.css('width', width);
                        });
                    }

                    function qtyPlaceholder(){
                        $('.qty').focus(function(){
                            var val = $(this).val();

                            if (typeof val != 'undefined' && val == 0){
                                $(this).val('');
                            }
                        })
                    }

                    qtyPlaceholder();

                    $.fn.textWidth = function () {
                        var $body = $('body');
                        var $this = $(this);
                        var text = $this.text();
                        var calc = '';
                        var width = 0;

                        if (text == '') {
                            text = $this.val();
                        }

                        calc = '<div class="dummy-helper"><span>' + text + '</span></div>';

                        $body.append(calc);

                        width = $('body').find('.dummy-helper:last span').width();

                        $body.find('.dummy-helper').remove();

                        return width;
                    };

                    $('.alert.dismissable').on('click', function (e) {
                        $(this).fadeOut();
                    });

                },
                finalize: function () {
                    // JavaScript to be fired on all pages, after page specific JS is fired
                }
            },
            // Home page
            'home': {
                init: function () {
                    function closeToast() {
                        $('.toast-close').click(function(e){
                            $(this).parent().toggle();
                        })
                    }

                    /************** FUNCTIONS CALL *********************/
                    $(document).ready(function () {
                        closeToast();
                    });
                },
                finalize: function () {
                    // JavaScript to be fired on the home page, after the init JS
                }
            },
            // Exercises page
            'exercises': {
                init: function () {

                    /**
                     * Infinite Scroll for Exercises page
                     */
                    function exerciseInfiniteScroll() {
                        $('.section-results .section-body .exercise-results').infinitescroll({
                            navSelector: ".infiniteNav",
                            // selector for the paged navigation (it will be hidden)
                            nextSelector: ".infiniteNavNext",
                            // selector for the NEXT link (to page 2)
                            itemSelector: ".exercise-result",
                            // selector for all items you'll retrieve
                            loading: {
                                finished: undefined,
                                finishedMsg: "<em>No more results.</em>",
                                msg: null,
                                msgText: "<em>Loading the next set of Exercises...</em>",
                                selector: null,
                                speed: 'fast',
                                start: undefined
                            },
                        }, function (newElems) {
                            createNewCourse();
                            addExerciseToCourse();
                        });
                    }

                    /**
                     * When user make changes on the Sort By search nav
                     * Submit the search form
                     */
                    function submitExerciseSearchOnChange() {
                        $('.sort-body input').change(function () {
                            $('.sort-body form').submit();
                        });
                    }

                    /**
                     * Create a new Course through ajax in order to add an Exercise to it
                     */
                    function createNewCourse() {
                        $('.exercise-result .popup-foot .form-course form').unbind('submit').submit(function (e) {
                            e.preventDefault();

                            var form = $(this);

                            var list = $(this).parent().parent().parent().find('.list-courses');

                            var exerciseId = list.data('exercise-id');

                            $.ajax({
                                type: 'POST',
                                url: form.attr('action'),
                                data: form.serialize(),
                                success: function (data) {
                                    var i = 1;
                                    $('.exercise-results .exercise-result').each(function (e) {
                                        $(this).find('.popup-body .list-courses').append('<li><div class="checkbox checkbox-md"><form action="/wp-content/themes/drrc/lib/Exercise/ajax-exercise.php"><input type="checkbox" name="courses" id="course' + data.Id + exerciseId + i + '"><label for="course' + data.Id + exerciseId + i + '"></label><input type="hidden" name="course_id" value="' + data.Id + '" /><input type="hidden" name="exercise_id" value="' + exerciseId + '" /><input type="hidden" name="action" class="action" value="add" /></form></div><!-- /.checkbox --><a href="/course-detail?id=' + data.Id + '">' + data.Name + '</a><a class="link-edit" href="/course-detail?id=' + data.Id + '"><i class="ico-gear"></i></a></li>');
                                        i++;
                                    })
                                    addExerciseToCourse();
                                },
                                dataType: 'json'
                            })

                            return false;
                        })
                    }

                    /**
                     * On the Exercises list page, clicking on a checkbox in the add to course popup
                     * will add/remove the exercise of that course
                     */
                    function addExerciseToCourse() {
                        $('.list-courses .checkbox input[name=courses]').unbind('change').change(function (e) {
                            var checkbox = $(this);
                            var form = checkbox.parent();

                            $.ajax({
                                type: 'POST',
                                url: form.attr('action'),
                                data: form.serialize(),
                                success: function (data) {
                                    if (checkbox.prop("checked")) {
                                        form.find('.action').val('remove');
                                    }
                                    else {
                                        form.find('.action').val('add');
                                    }
                                }
                            })
                        })
                    }

                    /************** FUNCTIONS CALL *********************/
                    $(document).ready(function () {
                        exerciseInfiniteScroll();
                        submitExerciseSearchOnChange();
                        createNewCourse();
                        addExerciseToCourse();
                    });
                }
            },
            // Single Product
            'single_product': {
                init: function () {

                    /**
                     * Calculate total when quantity is changed for a role
                     */
                    function calculateTotalOnChange() {
                        $('.table-buy .field').keyup(function (e) {
                            calculateFirstTotal();
                        });
                    }

                    /**
                     * When the single product page is loaded, calculate the total with 1 quantity
                     * for each role
                     */
                    function calculateFirstTotal() {

                        $('.table-buy .total span').html('0.00');

                        $('.table-buy .field').each(function (e) {

                            var total = $('.table-buy .total span').html();

                            if (typeof total == 'undefined' || total === '') {
                                total = 0.00;
                            }

                            var qty = $(this).val();

                            var rolePrice = $(this).parent().parent().attr('data-price');

                            if (typeof qty == 'undefined' || qty === '') {
                                qty = 0;
                            }

                            var total = parseFloat(total) + (parseFloat(qty) * parseFloat(rolePrice));

                            $('.table-buy .total span').html(total.toFixed(2));

                        });
                    }
					
					function changeCustomerCategory(){
						$('.table-buy .customer-category').on('change', function (e) {
							var id = $(this).val();
							
							if(id){
								var item = $(this).parents('.item');
								var price = $('input[name='+id+']').val();
								var priceFormatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
								
								if(item){
									item.attr('data-price', price);
									item.find('.item-price').text(priceFormatted);
									item.find('input.qty').attr('name', 'quantity['+id+']');
									calculateFirstTotal();
								}
							}
                        }).change();
					}
					
                    /**
                     * Create a new Course through ajax in order to add an Exercise to it
                     */
                    function createNewCourse() {
                        $('.widget-buy .popup-foot .form-course form').unbind('submit').submit(function (e) {
                            e.preventDefault();

                            var form = $(this);

                            var list = $(this).parent().parent().parent().find('.list-courses');

                            var exerciseId = list.data('exercise-id');

                            $.ajax({
                                type: 'POST',
                                url: form.attr('action'),
                                data: form.serialize(),
                                success: function (data) {
                                    var i = 1;
                                    $('.widget-buy').find('.popup-body .list-courses').each(function(){
                                        $(this).append('<li><div class="checkbox checkbox-md"><form action="/wp-content/themes/drrc/lib/Exercise/ajax-exercise.php"><input type="checkbox" name="courses" id="course' + data.Id + exerciseId + i +'"><label for="course' + data.Id + exerciseId + i + '"></label><input type="hidden" name="course_id" value="' + data.Id + '" /><input type="hidden" name="exercise_id" value="' + exerciseId + '" /><input type="hidden" name="action" class="action" value="add" /></form></div><!-- /.checkbox --><a href="/course-detail?id=' + data.Id + '">' + data.Name + '</a><a class="link-edit" href="/course-detail?id=' + data.Id + '"><i class="ico-gear"></i></a></li>');
                                        i++;
                                    });
                                    addExerciseToCourse();
                                },
                                dataType: 'json'
                            })

                            return false;
                        })
                    }

                    /**
                     * On the Exercises list page, clicking on a checkbox in the add to course popup
                     * will add/remove the exercise of that course
                     */
                    function addExerciseToCourse() {
                        $('.list-courses .checkbox input[name=courses]').unbind('change').change(function (e) {
                            var checkbox = $(this);
                            var form = checkbox.parent();

                            $.ajax({
                                type: 'POST',
                                url: form.attr('action'),
                                data: form.serialize(),
                                success: function (data) {
                                    if (checkbox.prop("checked")) {
                                        form.find('.action').val('remove');
                                    }
                                    else {
                                        form.find('.action').val('add');
                                    }
                                }
                            })
                        })
                    }

                    function submitAddToCart(){
                        $('a.form-btn').click(function(e){
                            e.preventDefault();

                            $('.form-buy > form').submit();
                        });
                    }

                    /************** FUNCTIONS CALL *********************/
                    $(document).ready(function () {
                        calculateTotalOnChange();
                        calculateFirstTotal();
                        createNewCourse();
                        addExerciseToCourse();
                        submitAddToCart();
						changeCustomerCategory();
                    });
                }
            },
            // Course Detail
            'course_detail': {
                init: function () {

                    (function(){
                        //$('.build-course-detail .section-head-initial').parent().siblings().children().next().slideUp();
                        $('.build-course-detail .section-primary.expanded .section-head-initial').siblings().slideDown();
                        $('.build-course-detail .section-primary.expanded .section-head-initial').find('img').toggleClass('white-rotated');
                        //$('.build-course-detail .section-primary:not(.expanded) .section-head').siblings().slideUp();
                        $(".role-info select option").text(function(i,t){
                            return t.length > 42 ? t.substr(0,39) + "..." : t;
                        });
                    })();


                    $('.build-course-detail .section-primary .section-head-initial').on('click', function() {
                        console.log('test2');
                        $(this).find('img').toggleClass('white-rotated');
                        $(this).siblings().slideToggle();
                        $('.exercise').removeClass('exercise-expanded');
                        $('.blue-arrow').removeClass('rotated');
                        $('.blue-arrow-bigger').removeClass('rotated');
                        $('.exercise-group-body').slideUp('fast');
                        $('.exercise-foot').slideUp('fast');
                        return false;
                    });

                     $('.exercise-group-head').on('click', function(){
                            $(this).next('.exercise-group-body').slideToggle();
                            $(this).find('img').toggleClass('rotated');
                        });

                    $('.exercise-body').click(function (event) {

                        var exercise = $(this).closest('.exercise');

                        $('.exercise').removeClass('exercise-expanded');
                        exercise.siblings().find('.exercise-body .exercise-aside img').removeClass('rotated');
                        var exAsideBlue = exercise.find('.exercise-aside img').stop();
                        if ($(exAsideBlue).hasClass('rotated')) {
                            $(exAsideBlue).removeClass('rotated');
                        } else {
                            $(exAsideBlue).addClass('rotated');
                        }

                        exercise.toggleClass('exercise-expanded')
                            .find('.exercise-foot').stop().slideToggle();

                        event.preventDefault();
                    });

                    if (window.matchMedia('(max-width: 768px)').matches){
                         $("td:empty").css('border', 'none');
                    }

                    /**
                     * Submit form with specific bulk action for the Student roster
                     */
                    function studentRosterAction() {
                        $('.roster-action').click(function (e) {
                            e.preventDefault();
                            var action = $(this).data('action');
                            var form = $('form.roster-form ');
                            var formUrl = form.attr('action');

                            form.attr('action', formUrl + '/' + action + '-student.php');

                            if (action == 'delete'){
                                var confirmed = confirm('Are you sure you want to delete these students?');
                                if (!confirmed){
                                    e.preventDefault();
                                }
                                else {
                                    form.submit();
                                }
                            }
                            else if (action === 'multiple-invoice') {
                                if ($("form.roster-form input:checkbox:checked").length > 0) {
                                    form.submit();
                                }
                                else {
                                    alert('Please select at least one student.');
                                }
                            }
                            else {
                                form.submit();
                            }
                        });

                        $('.toggle-edit-mode').click(function(e) {
                            e.preventDefault();

                            if (!$('.edit-mode').hasClass('hidden')) {
                                var action = $(this).data('action');
                                var form = $('form.roster-form ');
                                var formUrl = form.attr('action');

                                form.attr('action', formUrl + '/' + action + '-student.php');

                                form.submit();
                            }
                            else {

                                $('.edit-mode').toggleClass('hidden');

                                $('.table-students-secondary tr').toggleClass('editing')
                                    .find('.field').prop('disabled', function (i, v) {
                                    return !v;
                                });

                                setWidth($('.table-students-secondary .field'));
                            }
                        })

                    }

                    function addStudentToGroup() {
                        $('tr.add-student a.save').unbind('click').click(function (e) {

                            e.preventDefault();

                            var exercise = $(this).closest('.exercise');

                            var url = $(this).attr('href');
                            var row = $(this).parent().parent();
                            var data = {
                                'group_id': row.find('input[name=group_id]').val(),
                                'student_id': row.find('select[name=student_id]').val(),
                                'product_role_id': row.find('select[name=product_role_id]').val()
                            };

                            $.ajax({
                                'type': 'POST',
                                'url': url,
                                'data': data,
                                'complete': function (data) {
                                    window.location.hash = 'group-'+row.find('input[name=group_id]').val();
                                    location.reload();
                                }
                            });
                        });
                    }

                    function updateRole() {
                        $('tr.update-role a.save').unbind('click').click(function (e) {

                            e.preventDefault();

                            var url = $(this).attr('href');
                            var row = $(this).parent().parent();
                            var data = {
                                'group_id': row.find('input[name=group_id]').val(),
                                'course_id': row.find('input[name=course_id]').val(),
                                'role_id': row.attr('data-role-id'),
                                'product_id': row.find('select[name=product_id]').val()
                            };

                            $.ajax({
                                'type': 'POST',
                                'url': url,
                                'data': data,
                                'complete': function (data) {
                                    console.log(data);
                                    window.location.hash = 'group-'+row.find('input[name=group_id]').val();
                                    location.reload();
                                }
                            });
                        });

                        $('tr.update-role select[name="product_id"]').unbind('change').change(function (e) {

                            e.preventDefault();

                            var row = $(this).parent().parent().parent();
                            var url = row.find('input[name=update_url]').val();

                            var data = {
                                'group_id': row.find('input[name=group_id]').val(),
                                'course_id': row.find('input[name=course_id]').val(),
                                'role_id': row.attr('data-role-id'),
                                'product_id': row.find('select[name=product_id]').val()
                            };

                            $.ajax({
                                'type': 'POST',
                                'url': url,
                                'data': data,
                                'complete': function (data) {
                                    console.log(data);
                                    window.location.hash = 'group-'+row.find('input[name=group_id]').val();
                                    location.reload();
                                }
                            });
                        });

                    }

                    function unassignedGroupPlacement() {
                        $('tr select.unassigned-group').unbind('change').change(function (e) {

                            e.preventDefault();

                            var exercise = $(this).closest('.exercise');

                            var row = $(this).parent().parent().parent();

                            var url = row.find('input[name=url]').val();

                            var data = {
                                'group_id': row.find('select[name=group_id]').val(),
                                'course_id': row.find('input[name=course_id]').val(),
                                'student_id': row.attr('data-student-id'),
                                'product_role_id': row.find('select[name=product_role_id]').val()
                            };

                            $.ajax({
                                'type': 'POST',
                                'url': url,
                                'data': data,
                                'complete': function (data) {
                                    //console.log(data);
                                    window.location.hash = 'group-'+row.find('select[name=group_id]').val();
                                    location.reload();
                                }
                            });
                        });
                    }

                    function addNewStudentRowToGroup() {
                        $('.exercise-group-actions .link-add.add-student').click(function (e) {
                            e.preventDefault();

                            var cloneRow = $(this).parent().parent().find('.table-students tr.add-student').last();
                            cloneRow.before(cloneRow[0].outerHTML);
                            cloneRow.prev().removeClass('hidden');

                            addStudentToGroup();
                        });
                    }

                    function updateStudent() {
                        $('.table-students-secondary .student-actions .link-edit').click(function (e) {

                            var that = $(this);

                            var exercise = $(this).closest('.exercise');

                            e.preventDefault();

                            var form = $('form.roster-form ');
                            var formUrl = form.attr('action');

                            var url = formUrl + '/' + 'update-student.php';
                            var row = $(this).parent().parent().parent();
                            var data = {
                                'f_name': row.find('input[name=f_name]').val(),
                                'l_name': row.find('input[name=l_name]').val(),
                                'email': row.find('input[name=email]').val(),
                                'course_id': form.find('input[name=course_id]').val(),
                                'student_id': row.find('input[name=student_id]').val(),
                            };

                            var rowClass = row.attr('class');

                            if (rowClass === 'editing') {

                                that.html('<i class="ico-edit-purple"></i> Edit');

                                $.ajax({
                                    'type': 'POST',
                                    'url': url,
                                    'data': data,
                                    'success': function (data) {
                                        window.location.hash = exercise.attr('id');
                                        location.reload();
                                    }
                                });
                            }
                            else {
                                that.html('<i class="ico-edit-purple"></i> Done');
                            }
                        });
                    }

                    function displayEditCourseName() {
                        $('.nav-utilities .link-edit,.main-head .edit-course-name').click(function (e) {
                            e.preventDefault();

                            $(this).toggleClass('active');
                            $('.main-head h1').toggleClass('hidden');
                            $('.main-head .course-edit').toggleClass('hidden');
                        });
                    }

                    function submitImportForm() {
                        $('.file-upload form input[type=file]').change(function (e) {
                            $('.file-upload form').submit();
                        });
                    }

                    function displayEditActivityName() {
                        $('.exercise-actions .link-edit-activity').click(function (e) {
                            e.preventDefault();

                            $(this).toggleClass('active');

                            var closestExercise = $(this).closest('.exercise');

                            closestExercise.find('.exercise-entry h3').toggleClass('hidden');
                            closestExercise.find('.exercise-entry .activity-edit').toggleClass('hidden');
                        });

                        $('.activity-edit form .btn').click(function(e) {
                            $(this).parent().submit();
                        })
                    }

                    /**
                     *
                     */
                    function sortableGroup() {

                        var groups = document.getElementsByClassName("sortable-list");
                        for (var i = 0; i < groups.length; i++) {
                            var group = document.getElementById(groups[i].id);
                            var sortable = Sortable.create(
                                group,
                                {

                                    group: 'groups',
                                    onAdd: function (evt) {

                                        var roleId = evt.item.dataset.roleId;
                                        var groupId = evt.item.parentElement.dataset.groupId;
                                        var url = evt.item.parentElement.dataset.ajaxUrl;

                                        if (typeof roleId != 'undefined' && typeof groupId != 'undefined' && typeof url != 'undefined') {

                                            $.ajax({
                                                'type': 'POST',
                                                'url': url,
                                                'data': {
                                                    group_id : groupId,
                                                    role_id : roleId
                                                },
                                                'success': function (data) {
                                                },
                                                'complete': function (data) {
                                                    window.location.hash = 'group-'+groupId;
                                                    location.reload();
                                                }
                                            });
                                        }
                                    }
                                }
                            );
                        }
                    }

                    /**
                     *
                     */
                    function toggleExerciseOnReload() {
                        var hash = window.location.hash.substring(1);

                        if (typeof hash != 'undefined') {

                            if (hash.split('-')[0] === 'group'){
                                $("#" + hash).parent().parent().parent().toggleClass('exercise-expanded')
                                    .find('.exercise-foot').stop().slideToggle();
                                $("#" + hash).find('.exercise-group-body').stop().slideDown();
                            }
                            else if (hash.split('-')[0] === 'exercise') {

                                $("#" + hash).toggleClass('exercise-expanded')
                                    .find('.exercise-foot').stop().slideToggle();
                            }
                        }
                    }

                    /**
                     *
                     */
                    function prompts(){
                        $('.delete-course').click(function(e){
                            var confirmed = confirm('Are you sure you want to delete this course?');
                            if (!confirmed){
                                e.preventDefault();
                            }
                        })

                        $('.invoice-all').click(function(e){
                            var confirmed = confirm('Are you sure you want to send invoices? You will no longer be able to add or remove exercises.');
                            if (!confirmed){
                                e.preventDefault();
                            }
                        })

                        $('.delete-exercise').click(function(e){
                            var confirmed = confirm('Are you sure you want to delete this exercise?');
                            if (!confirmed){
                                e.preventDefault();
                            }
                        })

                        $('.delete-student').click(function(e){
                            var confirmed = confirm('Are you sure you want to delete these students?');
                            if (!confirmed){
                                e.preventDefault();
                            }
                        })
                    }

                    function selectAll(){
                        $('input[name="select-all"]').on('change', function () {

                            console.log(this.checked);

                            $('.table-students-secondary input[type="checkbox"]')
                                .prop('checked', this.checked)
                                .toggleClass("user-success");
                        });
                    }

                    function clearStudents(){
                        $('#clear-selected-students').on('click', function (e) {

                            e.preventDefault();

                            $('input[name="select-all"]').prop('checked', false);
                            $('.table-students-secondary input[type="checkbox"]')
                                .prop('checked', false);
                        });
                    }

                    // media query
                    if (window.matchMedia('(max-width: 1025px)').matches){
                        $('.nav-utilities ul li').removeClass('arrow_box');
                    }

                    /************** FUNCTIONS CALL *********************/
                    studentRosterAction();
                    addStudentToGroup();
                    addNewStudentRowToGroup();
                    updateStudent();
                    displayEditCourseName();
                    submitImportForm();
                    displayEditActivityName();
                    sortableGroup();
                    unassignedGroupPlacement();
                    updateRole();
                    toggleExerciseOnReload();
                    prompts();
                    selectAll();
                    clearStudents();
                }
            },
            // Build A course
            'build_a_course': {
                init: function () {

                    /**
                     *
                     */
                    function prompts() {
                        $('.delete-course').click(function (e) {
                            var confirmed = confirm('Are you sure you want to delete this course?');
                            if (!confirmed) {
                                e.preventDefault();
                            }
                        })
                    }

                    /************** FUNCTIONS CALL *********************/
                    prompts();
                },
                finalize: function () {
                    // JavaScript to be fired on the home page, after the init JS
                }
            },
            'group_assigner': {
                init: function () {

                    /**
                     *
                     */
                    function prompts() {
                        $('.delete-course').click(function (e) {
                            var confirmed = confirm('Are you sure you want to delete this course?');
                            if (!confirmed) {
                                e.preventDefault();
                            }
                        })
                    }
                    
                    function stepsSwitch() {
                        $('.nav-secondary li').click(function(e){
                            e.preventDefault();
                            $('.nav-secondary li').each(function(e){
                                $(this).removeClass('current');
                            })
                            $('.main-head-image').each(function(e){
                                $(this).removeClass('current');
                            })

                            $(this).addClass('current');
                            var id = $(this).find('a').attr('href');


                            $(id).addClass('current');
                        })
                    }

                    /************** FUNCTIONS CALL *********************/
                    prompts();
                    stepsSwitch();
                },
                finalize: function () {
                    // JavaScript to be fired on the home page, after the init JS
                }
            },
        }
        ;

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

// Load Events
    $(document).ready(UTIL.loadEvents);

})
(jQuery); // Fully reference jQuery after this point.
